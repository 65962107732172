.birthday {
    width: 100%;
    border: none;
    border-top-color:rgb(21, 42, 76)!important;
    border-bottom-color: rgb(21, 42, 76)!important;
    border-left-color: rgb(21, 42, 76)!important;
}
.birthday input {
    width: 100%;
    height: 40px;
    background: none;
    border: none!important;
    color: #fff;
    outline: none;
}

-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #fff;  

}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #fff;  
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #fff;  
}