.result {
    padding: 20px;
}

.result .result-list h4 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
    background: url('../../static/images/h3.png') no-repeat center;
    background-size: contain;
    height: 50px;
    line-height: 45px;
}

.result .result-list p {
    font-size: 15px;
    color: #fff;
    line-height: 150%;
    text-align: left;
}

.progress {
    font-size: 18px;
    margin: 20px 20px 40px 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #F8D48B;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.progress .title {
    color: #FFFFFF;
}

.progress .bar {

    margin-top: 0.5em;
    border-radius: 5px;
    background-color: #293959;
}

.progress .bar span {
    display: block;
    width: 50%;
    height: 0.5em;
    border-radius: 0.25em;
    background: linear-gradient(268deg, rgb(46, 182, 199), rgb(18, 118, 130));
    transition: all linear 0.1s;
    -webkit-transition: all linear 0.1s;
}

.progress .entire {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
}

.progress .entire span {
    display: block;
    height: 100%;
    width: 20%;
    background: #F8D48B;
}