:root {
    --s:  6;
    --h:  48;
    --speed:  1.5s;
}
.g-container  {
    width:  194px;
    height:  calc(var(--h)  *  1px);
    overflow:hidden;
    height:48px;
    /* background-color: pink; */
}
ul  {
    display:  flex;
    flex-direction:  column;
    animation:  move  calc(var(--speed)  *  var(--s))  steps(var(--s))  infinite;
    list-style: none;
    margin: 0;
    padding: 0;
}
ul li{
    height: 48px;
    animation: liMove calc(var(--speed)) infinite;
    display: flex;
    align-items: center;
}
.imgss{
    width:35.63px;
    height:35.63px;
    border-radius: 50%;
    
}
.namebox{
    width:165px;
    color: white;
    font-size: 15px;
 }
@keyframes liMove {
    0% {
        transform: translate(0, 0);
    }
    80%,
    100%  {
        transform: translate(0, -48px);
    }
}
@keyframes  move  {
    0%  {
        transform:  translate(0,  0);
    }
    100%  {
        transform:  translate(0,  calc(var(--s)  *  var(--h)  *  -1px));
    }
}